  import React, { useEffect, useRef, useState } from 'react';
  import { Link, useLocation } from 'react-router-dom';
  import LogoImg from '../../../assests/images/aqrotexnika.png';
  import LogoMobile from '../../../assests/images/aqrotexnikaNew.png';
  import HeaderTop from './components/HeaderTop/HeaderTop';
  import styles from './Header.module.scss';
  import {
    SVGPhone,
    SVGburgerMenu,
    SVGfaceBook,
    SVGinstagram,
    SVGsearch,
    SVGMobWp,
    SVGMobPhone
  } from '../../../assests/icons';
  import HeaderBurger from './components/HeaderBurger/HeaderBurger';
  import useScreenSize from '../../../hooks/useScreenSize';
  import useDebounce from '../../../hooks/useDebounce';
  import useUsers from '../../../hooks/useUsers';
  import {
    setInitial,
    setLastIndex,
    setProducts,
    setShowMore,
  } from '../../../redux/slices/productSlice';
  import { useDispatch } from 'react-redux';

  function Header() {
    const dispatch = useDispatch();
    const { getProducts } = useUsers();
    const [burgerMenuShow, setBurgerMenuShow] = useState(false);
    const isMobile = useScreenSize().isMobile;
    const [showInput, setShowInput] = useState(true);
    const [searchItem, setSearchItem] = useState('');

    const location = useLocation();
    const currentPath = location.pathname;

    // Check if the current URL contains /ru to determine language
    const isRussian = currentPath.includes('/ru');

    // Translations
    const translations = {
      searchPlaceholder: {
        az: "Axtarış",
        ru: "Поиск"
      }
    };

    const handleChangeBurgerMenuShow = () => {
      setBurgerMenuShow(!burgerMenuShow);
    };

    const handleChangeInput = (e) => {
      setSearchItem(e.target.value);
      if (e.target.value) {
        dispatch(setShowMore(false));
      } else {
        dispatch(setShowMore(true));
      }
    };

    const debounceSearchItem = useDebounce(searchItem, 1000);

    const getSearch = (item) => {
      // Function logic remains the same
    };

    // Adjust path checking to account for Russian URLs
    useEffect(() => {
      // Check for both Russian and Azerbaijani versions of the paths
      const isMainPage =
          currentPath === '/' ||
          currentPath === '/ana-sehife' ||
          currentPath === '/ru/ana-sehife' ||
          currentPath === '/texnika-satisi' ||
          currentPath === '/ru/texnika-satisi' ||
          currentPath === '/ru'; // Added this to catch the Russian root path

      if (isMainPage) {
        if (debounceSearchItem && searchItem.length) {
          const _ = null;
          getProducts({}, _, _, searchItem).then((data) => {
            if (data.data) {
              dispatch(setProducts(data.data.products));
              dispatch(setInitial(false));
            }
          });
        } else {
          getProducts({}, 0, 10, '').then((data) => {
            if (data.data) {
              dispatch(setLastIndex(data.data.total));
              dispatch(setProducts(data.data.products));
            }
          });
        }
      }
    }, [debounceSearchItem, currentPath]);

    useEffect(() => {
      // Check for both Russian and Azerbaijani versions of the paths
      const isMainPage =
          currentPath === '/' ||
          currentPath === '/ana-sehife' ||
          currentPath === '/ru/ana-sehife' ||
          currentPath === '/texnika-satisi' ||
          currentPath === '/ru/texnika-satisi' ||
          currentPath === '/ru'; // Added this to catch the Russian root path

      setShowInput(isMainPage);
      setSearchItem('');
    }, [currentPath]);

    // Helper function to get text based on language
    const getText = (key) => {
      return translations[key][isRussian ? 'ru' : 'az'];
    };

    // For debugging - remove this in production
    useEffect(() => {
      console.log('Current path:', currentPath);
      console.log('Is Russian:', isRussian);
      console.log('Should show input:', showInput);
    }, [currentPath, isRussian, showInput]);

    return (
        <div className={styles.header__wrapper}>
          <HeaderTop isRussian={isRussian} />
          <div className={styles.header}>
            <div className={styles.header__logo}>
              <div
                  onClick={handleChangeBurgerMenuShow}
                  className={styles.header__burgerMenu}
              >
                <SVGburgerMenu />
              </div>
              <Link to={isRussian ? "/ru/ana-sehife" : "/ana-sehife"}>
                <div className={styles.logoImgContainer}>
                  <img className={styles.logoImg} src={isMobile ? LogoMobile : LogoImg} alt="logo" />
                </div>
              </Link>
            </div>
            <div className={styles.header__info}>
              {isMobile ? (
                  <div className={styles.socials}>
                    <Link to="https://www.facebook.com/aqrotexnika.az">
                      <SVGMobWp />
                    </Link>
                    <Link to="https://www.facebook.com/aqrotexnika.az">
                      <SVGMobPhone />
                    </Link>
                    <Link to="https://www.facebook.com/aqrotexnika.az">
                      <SVGfaceBook />
                    </Link>
                    <Link to="https://instagram.com/aqrotexnika.az">
                      <SVGinstagram />
                    </Link>
                  </div>
              ) : (
                  <h2>
                <span>
                  <SVGPhone />
                </span>
                    +994(51)230 00 30
                  </h2>
              )}
            </div>
            {/* Modified this condition to always show search on main pages regardless of language */}
            {showInput && !isMobile && (
                <div className={styles.header__searchBlock}>
                  <div className={styles.header__search}>
                    <input
                        autoCapitalize="off"
                        type="text"
                        placeholder={getText('searchPlaceholder')}
                        autoComplete="off"
                        value={searchItem}
                        onChange={handleChangeInput}
                    />
                    <SVGsearch />
                  </div>
                </div>
            )}
          </div>
          {burgerMenuShow && (
              <HeaderBurger
                  handleChangeBurgerMenuShow={handleChangeBurgerMenuShow}
                  isRussian={isRussian}
              />
          )}
        </div>
    );
  }

  export default Header;