// Function to check if the URL contains '/ru'
const isRussianUrl = () => {
  if (typeof window !== 'undefined') {
    return window.location.pathname.includes('/ru');
  }
  return false;
};

// Get the appropriate company links based on language
export const getCompany = () => {
  const isRussian = isRussianUrl();

  return [
    {
      id: 1,
      link: isRussian ? "/ru/ana-sehife" : "/ana-sehife",
      title: isRussian ? "Главная" : "Ana səhifə",
    },
    {
      id: 2,
      link: isRussian ? "/ru/texnika-satisi" : "/texnika-satisi",
      title: isRussian ? "Продажа техники" : "Texnika satışı",
    },
    {
      id: 3,
      link: isRussian ? "/ru/aqroservis" : "/aqroservis",
      title: isRussian ? "Агросервис" : "Aqroservis",
    },
    // {
    //   id: 4,
    //   link: isRussian ? "/ru/diger-xidmetler" : "/diger-xidmetler",
    //   title: isRussian ? "Другие услуги" : "Digər xidmətlər",
    // },
    {
      id: 5,
      link: isRussian ? "/ru/haqqimizda" : "/haqqimizda",
      title: isRussian ? "О нас" : "Haqqımızda",
    },
    // {
    //   id: 6,
    //   link: isRussian ? "/ru/bloq" : "/bloq",
    //   title: isRussian ? "Блог" : "Bloq",
    // },
    {
      id: 7,
      link: isRussian ? "/ru/elaqe" : "/elaqe",
      title: isRussian ? "Контакты" : "Əlaqə",
    },
  ];
};

// Get the appropriate catalog links based on language
export const getCatalog = () => {
  const isRussian = isRussianUrl();

  return [
    {
      id: Date.now() + 1,
      title: isRussian ? "Тракторы" : "Traktorlar",
      link: isRussian ? "/ru/traktorlar" : "/traktorlar"
    },
    {
      id: Date.now() + 2,
      title: isRussian ? "Комбайны" : "Kombaynlar",
      link: isRussian ? "/ru/kombaynlar" : "/kombaynlar"
    },
    {
      id: Date.now() + 3,
      title: isRussian ? "Агрегаты" : "Aqreqatlar",
      link: isRussian ? "/ru/aqreqatlar" : "/aqreqatlar"
    },
  ];
};

// Contact info is mostly the same, just updating the website text
export const getContact = () => {
  const isRussian = isRussianUrl();

  return [
    { id: 21, title: "+994 (51) 230 00 30", link: "tel:+994512300030" },
    {
      id: 22,
      title: "www.agrotexnika.az",
      link: isRussian ? "/ru/ana-sehife" : "/ana-sehife"
    },
    {
      id: 23,
      title: "sales@agtotexnika.az",
      link: "mailto:sales@agtotexnika.az",
    },
  ];
};

// For direct use in components that don't handle the language logic themselves
export const company = getCompany();
export const catolog = getCatalog();
export const contact = getContact();