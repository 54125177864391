// Define translations for the header items
const headerItems = {
  az: [
    {
      id: 1,
      url: "/ana-sehife",
      title: "Ana səhifə",
    },
    {
      id: 2,
      url: "/texnika-satisi",
      title: "Texnika satışı",
    },
    {
      id: 3,
      url: "/aqroservis",
      title: "Aqroservis",
    },
    {
      id: 5,
      url: "/haqqimizda",
      title: "Haqqımızda",
    },
    {
      id: 7,
      url: "/elaqe",
      title: "Əlaqə",
    },
  ],
  ru: [
    {
      id: 1,
      url: "/rana-sehife",
      title: "Главная",
    },
    {
      id: 2,
      url: "/texnika-satisi",
      title: "Продажа техники",
    },
    {
      id: 3,
      url: "/raqroservis",
      title: "Агросервис",
    },
    {
      id: 5,
      url: "/rhaqqimizda",
      title: "О нас",
    },
    {
      id: 7,
      url: "/elaqe",
      title: "Контакты",
    },
  ]
};

// Export the original items for backward compatibility
export const mockHeaderItems = headerItems.az;

// Export a function that can be used in components
export const getLanguageItems = (pathname) => {
  const isRussian = pathname.includes('/ru');
  return isRussian ? headerItems.ru : headerItems.az;
};