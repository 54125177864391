import React, { useEffect, useState } from 'react';
import styles from './HeaderTop.module.scss';
import {
  SVGepmtyWp,
  SVGfaceBook,
  SVGinstagram,
} from '../../../../../assests/icons';
import { getLanguageItems, mockHeaderItems } from '../../../../../mock/mockHeaderItems';
import { Link, useLocation } from 'react-router-dom';

function HeaderTop() {
  const location = useLocation();
  const pathname = location.pathname;
  const isRussian = pathname.includes('/ru');
  const [activeLink, setActiveLink] = useState(null);

  // Get menu items based on language
  const headerItems = getLanguageItems(pathname);

  useEffect(() => {
    // For Russian pages, we need to check with the /ru prefix
    const currentPath = pathname;

    // Find active link in the current language's items
    const activeItem = headerItems.find((item) => item.url === currentPath);

    // Default to texnika-satisi/Продажа техники if we're on the home page
    const initialPath = isRussian ? '/ru/texnika-satisi' : '/texnika-satisi';
    const initial = headerItems.find((item) => item.url === initialPath);

    if (pathname === '/' || pathname === '/ru') {
      setActiveLink(initial?.id);
    }

    if (activeItem) {
      setActiveLink(activeItem.id);
    }
  }, [pathname, headerItems, isRussian]);

  // Generate language switch URL
  const getLanguageSwitchUrl = () => {
    if (isRussian) {
      // Remove /ru from the path
      return pathname.replace('/ru', '');
    } else {
      // Add /ru to the path
      return `/ru${pathname}`;
    }
  };

  return (
      <div className={styles.headerTop}>
        <div className={styles.headerTop__left}>
          {headerItems?.map((item) => (
              <Link
                  style={{ color: activeLink === item.id ? '#0AA349' : 'inherit' }}
                  key={item.id}
                  to={item.url}
              >
                <span>|</span>
                {item.title}
              </Link>
          ))}
        </div>
        <div className={styles.headerTop__right}>
          <a href="https://www.facebook.com/aqrotexnika.az" target="_blank" rel="noopener noreferrer">
            <SVGfaceBook />
          </a>
          <a href="https://instagram.com/aqrotexnika.az" target="_blank" rel="noopener noreferrer">
            <SVGinstagram />
          </a>
          {/* Language switcher */}
          <Link to={getLanguageSwitchUrl()} className={styles.langSwitcher}>
            {isRussian ? "AZ" : "RU"}
          </Link>
        </div>
      </div>
  );
}

export default HeaderTop;