import React, { useState, useEffect } from 'react';
import FooterList from './components/FooterList';
import CopyRight from './components/CopyRight';
import styles from './FooterStyle.module.scss';
import { getCompany, getCatalog, getContact } from '../../../mock/mockFooter';

export default function Footer() {
    // Check if URL contains '/ru' to determine language
    const [isRussian, setIsRussian] = useState(false);
    const [company, setCompany] = useState([]);
    const [catalog, setCatalog] = useState([]);
    const [contact, setContact] = useState([]);

    // Initialize language and data on component mount
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const isRu = window.location.pathname.includes('/ru');
            setIsRussian(isRu);

            // Get the appropriate data based on language
            setCompany(getCompany());
            setCatalog(getCatalog());
            setContact(getContact());
        }
    }, []);

    // Header text based on language
    const headers = {
        company: isRussian ? "Прямые ссылки" : "Birbaşa keçidlər",
        catalog: isRussian ? "Каталог" : "Kataloq",
        contacts: isRussian ? "Контакты" : "Əlaqələr"
    };

    return (
        <div className={styles.main}>
            <div className={styles.list}>
                <FooterList header={headers.company} list={company} />
                <FooterList header={headers.catalog} list={catalog} />
                <FooterList header={headers.contacts} list={contact} />
            </div>
            <CopyRight />
        </div>
    );
}